import React, { PureComponent } from "react";
import { navigate } from "gatsby";

import PageNotFound from "../components/PageNotFound/PageNotFound";

class NotFound extends PureComponent {
	componentDidMount() {
		navigate("/");
	}

	render() {
		return <PageNotFound />;
	}
}

export default NotFound;
