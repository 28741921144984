import React from "react";
import { navigate } from "gatsby";

import styles from "./PageNotFound.module.scss";

function PageNotFound() {
	return (
		<section className={styles.wrapper__main}>
			<p className={styles.section__title}>404</p>
			<p className={styles.section__body}>Page not found</p>

			<p onClick={() => navigate("/")} className={styles.section__btn} role="presentation">
				Back to Home
			</p>
		</section>
	);
}

export default PageNotFound;
